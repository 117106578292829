import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackBar(message: string, alertType: String) {
    if (alertType == 'error') {
      this.snackBar.open(message, '', {
        duration: 3500,
        panelClass: ['snack-error'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } else if (alertType == 'info') {
      this.snackBar.open(message, '', {
        duration: 3500,
        panelClass: ['snack-info'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }
}
