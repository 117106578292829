import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // Base url
  private readonly baseurl = environment.apiUrl;

  // Datos usuario
  private userSubject: BehaviorSubject<any | null>;
  public user: Observable<any | null>;

  // Permisos usuario
  private permisosSubject: BehaviorSubject<any | null>;
  public permisos: Observable<any | null>;

  constructor(
    private readonly http: HttpClient,
    private router: Router,
  ) {
    const encodedUser = localStorage.getItem('user');
    this.userSubject = new BehaviorSubject(
      encodedUser ? this.decodeFromBase64(encodedUser) : null,
    );
    this.user = this.userSubject.asObservable();

    const encodedPermisos = localStorage.getItem('permisos');
    this.permisosSubject = new BehaviorSubject(
      encodedPermisos ? this.decodeFromBase64(encodedPermisos) : null,
    );
    this.permisos = this.permisosSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  public get permisosValue() {
    return this.permisosSubject.value;
  }

  enviarOtpLogin(form: any): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}/otp/enviar_otp_login`, form)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  validarOtpLogin(form: any): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}/otp/validar_otp_login`, form)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  datosLogin() {
    return this.http
      .post<any>(`${this.baseurl}/fundcore/datos_login`, { test: 'test' })
      .pipe(
        map((user) => {
          // Datos usuario
          const encodedUser = this.encodeToBase64(user.data);
          localStorage.setItem('user', encodedUser);
          this.userSubject.next(user.data);

          // Datos permiso
          const encodedPermisos = this.encodeToBase64(user.data.permisos);
          localStorage.setItem('permisos', encodedPermisos);
          this.permisosSubject.next(user.data.permisos);

          return user;
        }),
      );
  }

  logout() {
    localStorage.clear();
    this.userSubject.next(null);
    this.permisosSubject.next(null);
    this.router.navigate(['/']);
  }

  // Lanza una excepción `Error` tipo client-side o server-side
  private errorHandler(error: HttpErrorResponse): never {
    let errorMessage = 'Unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    throw new Error(errorMessage);
  }

  private encodeToBase64(data: any): string {
    const jsonString = JSON.stringify(data);
    return btoa(jsonString);
  }

  private decodeFromBase64(encodedData: string): any {
    const decodedString = atob(encodedData);
    return JSON.parse(decodedString);
  }
}
